import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../components/Layout';
import Search from '../components/shared/Search/search';
import { cmsCollections, SearchRecords } from '../services/cmsService';
import { IUserprofileSearchResult } from '../models/entityModels';
import SeedPeopleFilterBar, { ISeedPeopleFilters } from '../components/SeedPeopleFilterBar/seedPeopleFilterBar';
import { graphql, Link } from 'gatsby';
import { IFilterItem } from '../components/shared/FilterDropdown/filterDropdown';
import { interests } from '../constants/data';
import PaginatedContent from '../components/shared/PaginatedContent/PaginatedContent';
import SEO from "../components/seo";
import placeholderImage from '../assets/images/placeholder.jpg';

import '../helpers/fontawesome';
import UserDetail from '../components/UserDetail/UserDetail';
import { getIsUserAuthenticated } from '../services/auth';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../msal/authConfig';
import ToastContainer, { EMessageModeEnum } from '../components/shared/Toast/ToastContainer';
import { getBadgeJSX } from '../components/shared/Badge/MoodleBadge';
import { getBadges } from '../helpers/functions';

const SearchSeedPeople = ({data, navigation}) => {
    const { instance } = useMsal();
    const [ searchResult, setSearchResult ] = useState(null as IUserprofileSearchResult[]);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ recordCount, setRecordCount ] = useState(null as number);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);
    // const [ badgeJSX, setbadgeJSX ] = useState(null);

    const [link, setLink] = useQueryParam("id", StringParam);

    const { seedpeopledirectoryTitle, seedpeopledirectoryDescription } = data?.seo?.nodes[0];

    const filtersRef = useRef(null);

    useEffect(() => {
        const fn = async () => {
            await applySearchTerm(searchTerm, pageNumber, pageSize);
        }

        if (!searchResult && !link) fn();
    }, []);

    const applySearchTerm = async (term: string, pn = pageNumber, ps = pageSize, filters: ISeedPeopleFilters = null) => {
        if (getIsUserAuthenticated()) {
            setSearchTerm(term);
            setPageNumber(pn);
            setPageSize(ps);
        
            if (!term) term = ' ';
            if (!filters) filters = filtersRef?.current?.getFilters();
            
            setIsLoading(true);
            try {
                const data = await SearchRecords('', cmsCollections.Userprofiles, term, ps, pn, filters).then((res: any) => res ? res.data : []);
                
                let dataWithBadges = [];
                for (let index = 0; index < data.records.length; index++) {
                    const user = data.records[index];
                    let badges = null;
                    if (user?.moodleuserid) {
                        badges = await getBadges(user?.moodleuserid, showMessage)
                    }

                    dataWithBadges.push({
                        ...user,
                        badges
                    });
                }
                
                setSearchResult(dataWithBadges);
                setRecordCount(data.recordCount);
            }
            catch (e: any) {
                console.log(e.description);
            }
            finally {
                setIsLoading(false);
            }
        }
    };
    
    const getInterests = (user: IUserprofileSearchResult): string[] => {
        let userInterests: string[] = [];

        Object.keys(user).forEach((prop: any) => {
            const interest: IFilterItem = interests.find((i: IFilterItem) => i.key === prop);
            if (interest && user[prop] === true) {
                userInterests.push(interest.label);
            }
        });
        return userInterests;
    };

    const changePageNo = (pn: number) => {
        applySearchTerm(searchTerm, pn);
    };

    const changePageSize = (ps: number) => {
        applySearchTerm(searchTerm, 1, ps);
    };

    const onFilterChanged = (fl: ISeedPeopleFilters) => {
        applySearchTerm(searchTerm, pageNumber, pageSize, fl);
    };

    const getUrl = (user: IUserprofileSearchResult) => {
        return user ? `?id=${user._id}` : '';
    };

    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

    return (
        <Layout>
            <ToastContainer message={message} mode={messageMode} />
            <SEO title={seedpeopledirectoryTitle} description={seedpeopledirectoryDescription}></SEO>
            {
                link ? <UserDetail id={link} /> :

                <Container className="search-seedpeople" key="search-seedpeople">
                <div className="sp-header">
                    <h4>Seed People Directory</h4>
                    <Link className="btn btn-outline-secondary btn-md" role="button" to="/map">
                        <FontAwesomeIcon icon='map-marker-alt' className="fa" />&nbsp;&nbsp;Map View
                    </Link>
                </div>
                <p>
                    Want to use the map to zoom to location instead? Click on 'Map View'<br/>
                    The map will only show you - Seed suppliers, Seed collectors, Seed production area and Nurseries, for all others searches please use this directory.
                </p>
                
                <Search key="search"
                        onSearchByTerm={applySearchTerm}
                        minTermLength={0}
                        placeHolder="Search for...  (first name, surname, location, organisation)"
                />

                <SeedPeopleFilterBar ref={filtersRef} onFilterChanged={ onFilterChanged } />

                {
                    !getIsUserAuthenticated() ?
                    <div>
                        <div className="sp-public-view">
                            You cannot see the participants because you have not signed in to Florabank. To Visit the sign in/up page to join or sign in to Florabank and browse the Seed Network.
                        </div>
                        <br></br>
                        <Button className="btn btn-success" onClick={(() => instance.loginRedirect(loginRequest))}>Click here to Sign In/Up</Button>
                    </div>
                    :
                    <PaginatedContent   pageNumber={ pageNumber }
                                    pageSize={ pageSize }
                                    recordCount={ recordCount }
                                    onPageChanged={ changePageNo }
                                    onPageSizeChanged={ changePageSize }
                                    isLoading={isLoading}
                    >
                        {
                            !searchResult || !searchResult.length ? <></> :
                            searchResult.map((user: IUserprofileSearchResult, u: number) => {
                                const name = user && user.firstname + ' ' + user.lastname;
                                const interests = getInterests(user);

                                

                                return (
                                    !user ? <></> :
                                    <Link className="upf-link" to={getUrl(user)}>
                                        <Card key={u}>
                                            <Card.Body>
                                                <Row className="sp-search-result">
                                                    <Col md={3} lg={2} className="sp-col-img">
                                                        <img src={user?.userPhotoExt[0]?.url || placeholderImage}></img>
                                                    </Col>
                                                    <Col sm={7} md={5} lg={3} className="sp-col">
                                                        <span className="sp-name">{name}</span>
                                                        <span className="sp-details">{user.email}</span>
                                                        <span className="sp-details">{user.mobilenumber}</span>
                                                        <span className="sp-details">{user.organisation}</span>
                                                        <span className="sp-details">{user.suburb}, {user.state}</span>
                                                    </Col>
                                                    <Col sm={5} md={4} lg={3} className="sp-col-interests">
                                                        <h6>Interests:</h6>
                                                        <ul>
                                                            {
                                                                !interests ? <></> :
                                                                interests.map((interest: string, i: number) => (
                                                                    <li key={i}>{interest}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </Col>
                                                    <Col sm={2} md={4} lg={4} className="sp-col-interests">
                                                        { user?.moodleuserid && user?.badges.length ? 
                                                            (
                                                            <>
                                                                <h6>Badges Earned:</h6>
                                                                <div className="badges">
                                                                    {
                                                                    !user?.badges ? <></> : 
                                                                    user?.badges.map(badge => {
                                                                        return getBadgeJSX(badge)
                                                                    })
                                                                    }
                                                                </div>    
                                                            </>
                                                            ) : <></>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                );
                            })
                        }
                    </PaginatedContent>
                }
            </Container>
            }
        </Layout>
    );
};

export const query = graphql`
  {
    seo: allStrapiSeo {
        nodes {
          seedpeopledirectoryDescription
          seedpeopledirectoryTitle
        }
      },   
  }
`

export default SearchSeedPeople;