export interface IGuideline {
    title: string;
    link: string;
    contents: string;
    sortOrder: number;
    blurb: string;
    image: any;
    sections: ISection[];
    pdfFile: {
        publicURL: string;
    };
};

export interface ISection {
    title: string;
    link: string;
    paragraphs: IParagraph[];
};

export interface IParagraph {
    imagesOrientation: any;
    images: any;
    contents: string;
};

export interface IUserprofileSeedInterestData {
    seedCollectorData: any;
    seedProductionAreaData: any;
    researchOrganisationData: any;
    nurseryData: any;
    landscapeRestorationData: any;
    seedBankData: any;
    seedTestingData: any;
    otherServiceData: any;
};

export interface IOrgSeedInterestData {
    seedCollectorData: any;
    seedProductionAreaData: any;
    researchOrganisationData: any;
    nurseryData: any;
    landscapeRestorationData: any;
    seedBankData: any;
    seedTestingData: any;
    otherServiceData: any;
};

export interface IUserprofile {
    id: any;
    firstname: string;
    lastname: string;
    email: string;
    postcode: string;
    suburb: string;
    state: string;
    mobilenumber: string;
    organisation: string;
    isUserVisibile: boolean;
    useOrgSeedInterest: boolean;
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isOther: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    userData: IUserprofileSeedInterestData;
    userNewsletterSubscribe: boolean;
    userPhoto: any;
    lat: string;
    long: string;
    organisationId: string;
    moodleuserid?: string;
    badges?: IMoodleBadge[];
};

export interface IUserprofileSubmitData { 
    firstname: string;
    lastname: string;
    email: string;
    postcode: string;
    suburb: string;
    state: string;
    mobilenumber: string;
    organisation: string;
    isUserVisibile: boolean;
    useOrgSeedInterest: boolean;
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isOther: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    seedCollectorCanopyTree: boolean;
    seedCollectorGroundLayer: boolean;
    seedCollectorOther: boolean;
    seedCollectorSeedMidStory: boolean;
    seedCollectorWetLand: boolean;
    seedCollectorSpecialistCleaning: boolean;
    seedCollectorSpecialistStorage: boolean;
    seedCollectorLocation: string;
    seedCollectorDistance: string;
    seedCollectorMonths: string;
    seedCollectorServiceExpertiseDescription: string;
    seedProductionAreaCanopyTree: boolean;
    seedProductionAreaGroundLayer: boolean;
    seedProductionAreaSeedMidStory: boolean;
    seedProductionAreaWetLand: boolean;
    seedProductionAreaOther: boolean;
    seedProductionAreaSpecialistCleaning: boolean;
    seedProductionAreaSpecialistStorage: boolean;
    seedProductionAreaLocation: string;
    seedProductionAreaDistance: string;
    seedProductionAreaMonths: string;
    seedProductionAreaServiceExpertiseDescription: string;
    ResearchOrgDescription: string;
    nurseryServiceInNT: boolean;
    nurseryServiceInWA: boolean;
    nurseryServiceInSA: boolean;
    nurseryServiceInVIC: boolean;
    nurseryServiceInTAS: boolean;
    nurseryServiceInACT: boolean;
    nurseryServiceInNSW: boolean;
    nurseryServiceInQLD: boolean;
    nurseryCapacity: string;
    landscapeRestorationNT: boolean;
    landscapeRestorationWA: boolean;
    landscapeRestorationSA: boolean;
    landscapeRestorationVIC: boolean;
    landscapeRestorationTAS: boolean;
    landscapeRestorationACT: boolean;
    landscapeRestorationNSW: boolean;
    landscapeRestorationQLD: boolean;
    seedBankNT: boolean;
    seedBankWA: boolean;
    seedBankSA: boolean;
    seedBankVIC: boolean;
    seedBankTAS: boolean;
    seedBankACT: boolean;
    seedBankNSW: boolean;
    seedBankQLD: boolean;
    seedBankDescription: string;
    seedTestingNT: boolean;
    seedTestingWA: boolean;
    seedTestingSA: boolean;
    seedTestingVIC: boolean;
    seedTestingTAS: boolean;
    seedTestingACT: boolean;
    seedTestingNSW: boolean;
    seedTestingQLD: boolean;
    otherServiceNT: boolean;
    otherServiceWA: boolean;
    otherServiceSA: boolean;
    otherServiceVIC: boolean;
    otherServiceTAS: boolean;
    otherServiceACT: boolean;
    otherServiceNSW: boolean;
    otherServiceQLD: boolean;
    otherServiceDescription: string;
    userData: IUserprofileSeedInterestData;
    userNewsletterSubscribe: boolean;
    userPhoto: any;
};

export interface IOrgSubmitData { 
    orgName: string;
    orgAddress: string;
    orgPostcode: string;
    orgState: string;
    orgContactNumber: string;
    orgWebsite: string;
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isOther: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    seedCollectorCanopyTree: boolean;
    seedCollectorGroundLayer: boolean;
    seedCollectorOther: boolean;
    seedCollectorSeedMidStory: boolean;
    seedCollectorWetLand: boolean;
    seedCollectorSpecialistCleaning: boolean;
    seedCollectorSpecialistStorage: boolean;
    seedCollectorLocation: string;
    seedCollectorDistance: string;
    seedCollectorMonths: string;
    seedCollectorServiceExpertiseDescription: string;
    seedProductionAreaCanopyTree: boolean;
    seedProductionAreaGroundLayer: boolean;
    seedProductionAreaSeedMidStory: boolean;
    seedProductionAreaWetLand: boolean;
    seedProductionAreaOther: boolean;
    seedProductionAreaSpecialistCleaning: boolean;
    seedProductionAreaSpecialistStorage: boolean;
    seedProductionAreaLocation: string;
    seedProductionAreaDistance: string;
    seedProductionAreaMonths: string;
    seedProductionAreaServiceExpertiseDescription: string;
    ResearchOrgDescription: string;
    nurseryServiceInNT: boolean;
    nurseryServiceInWA: boolean;
    nurseryServiceInSA: boolean;
    nurseryServiceInVIC: boolean;
    nurseryServiceInTAS: boolean;
    nurseryServiceInACT: boolean;
    nurseryServiceInNSW: boolean;
    nurseryServiceInQLD: boolean;
    nurseryCapacity: string;
    landscapeRestorationNT: boolean;
    landscapeRestorationWA: boolean;
    landscapeRestorationSA: boolean;
    landscapeRestorationVIC: boolean;
    landscapeRestorationTAS: boolean;
    landscapeRestorationACT: boolean;
    landscapeRestorationNSW: boolean;
    landscapeRestorationQLD: boolean;
    seedBankNT: boolean;
    seedBankWA: boolean;
    seedBankSA: boolean;
    seedBankVIC: boolean;
    seedBankTAS: boolean;
    seedBankACT: boolean;
    seedBankNSW: boolean;
    seedBankQLD: boolean;
    seedBankDescription: string;
    seedTestingNT: boolean;
    seedTestingWA: boolean;
    seedTestingSA: boolean;
    seedTestingVIC: boolean;
    seedTestingTAS: boolean;
    seedTestingACT: boolean;
    seedTestingNSW: boolean;
    seedTestingQLD: boolean;
    otherServiceNT: boolean;
    otherServiceWA: boolean;
    otherServiceSA: boolean;
    otherServiceVIC: boolean;
    otherServiceTAS: boolean;
    otherServiceACT: boolean;
    otherServiceNSW: boolean;
    otherServiceQLD: boolean;
    otherServiceDescription: string;
};

export interface IGeoPostCodeResponse {
    isSuccess: boolean;
    postcodes: IGeoPostCode;
}

export interface IGeoPostCode {
    id: any;
    postcode: string;
    state: string;
    lat: string;
    long: string;
}

export interface IOrganisation {
    id: any;
    orgName: string;
    orgAddress: string;
    orgPostcode: string;
    orgState: string;
    orgContactNumber: string;
    orgWebsite: string;
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isOther: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    organisationId?: string;
    orgData?: IOrgSeedInterestData;
};

export interface IDraftOrganisation {
    id: string;
    images: IImage[];
    imagesOrientation?: string;
};

export interface IImage {
    _id: string;
    name: string;
    width: number;
    height: number;
    url: string;
    formats: IImageFormats;
};

export interface IImageFormats {
    thumbnail: IFormatSub;
    small: IFormatSub;
    medium: IFormatSub;
    large: IFormatSub;
};

export interface IFormatSub {
    name: string;
    hash: string;
    width: number,
    height: string;
    url: string;
    childImageSharp: any;
    childrenImageSharp: any;
};

export const EUserProfileKeys = Object.freeze({ 
    id : "id",
    firstname : "firstname",
    lastname : "lastname",
    email : "email",
    postcode : "postcode",
    state : "state",
    mobilenumber : "mobilenumber",
    suburb : "suburb",
    organisation : "organisation",
    isUserVisibile : "isUserVisibile",
    useOrgSeedInterest : "useOrgSeedInterest",
    isSeedCollector : "isSeedCollector",
    isResearchOrganisation : "isResearchOrganisation",
    isSeedBank : "isSeedBank",
    isSeedProductionArea : "isSeedProductionArea",
    isLandscapeRestoration : "isLandscapeRestoration",
    isOther : "isOther",
    isNursery : "isNursery",
    isSeedTesting : "isSeedTesting",
    userData : "userData",
    userNewsletterSubscribed: "userNewsletterSubscribed"
});

export const ESeedCollectorDataFields = Object.freeze({ 
    seedCollectorCanopyTree: "seedCollectorCanopyTree",
    seedCollectorGroundLayer: "seedCollectorGroundLayer",
    seedCollectorOther: "seedCollectorOther",
    seedCollectorSeedMidStory: "seedCollectorSeedMidStory",
    seedCollectorWetLand: "seedCollectorWetLand",
    seedCollectorLocation: "seedCollectorLocation",
    seedCollectorDistance: "seedCollectorDistance",
    seedCollectorMonths: "seedCollectorMonths",
    seedCollectorSpecialistCleaning: "seedCollectorSpecialistCleaning",
    seedCollectorSpecialistStorage: "seedCollectorSpecialistStorage",
    seedCollectorServiceExpertiseDescription: "seedCollectorServiceExpertiseDescription",
});

export const ESeedProductionAreaDataFields = Object.freeze({ 
    seedProductionAreaCanopyTree: "seedProductionAreaCanopyTree",
    seedProductionAreaGroundLayer: "seedProductionAreaGroundLayer",
    seedProductionAreaOther: "seedProductionAreaOther",
    seedProductionAreaSeedMidStory: "seedProductionAreaSeedMidStory",
    seedProductionAreaWetLand: "seedProductionAreaWetLand",
    seedProductionAreaLocation: "seedProductionAreaLocation",
    seedProductionAreaDistance: "seedProductionAreaDistance",
    seedProductionAreaMonths: "seedProductionAreaMonths",
    seedProductionAreaSpecialistCleaning: "seedProductionAreaSpecialistCleaning",
    seedProductionAreaSpecialistStorage: "seedProductionAreaSpecialistStorage",
    seedProductionAreaServiceExpertiseDescription: "seedProductionAreaServiceExpertiseDescription",
});

export const EResearchOrgDataFields = Object.freeze({ 
    ResearchOrgDescription: "ResearchOrgDescription"
});

export const ENurseryDataFields = Object.freeze({ 
    nurseryServiceInNT: "nurseryServiceInNT",
    nurseryServiceInWA: "nurseryServiceInWA",
    nurseryServiceInSA: "nurseryServiceInSA",
    nurseryServiceInVIC: "nurseryServiceInVIC",
    nurseryServiceInTAS: "nurseryServiceInTAS",
    nurseryServiceInACT: "nurseryServiceInACT",
    nurseryServiceInNSW: "nurseryServiceInNSW",
    nurseryServiceInQLD: "nurseryServiceInQLD",
    nurseryCapacity: "nurseryCapacity",
});

export const ELandscapeRestorationDataFields  = Object.freeze({ 
    landscapeRestorationNT: "landscapeRestorationNT",
    landscapeRestorationWA: "landscapeRestorationWA",
    landscapeRestorationSA: "landscapeRestorationSA",
    landscapeRestorationVIC: "landscapeRestorationVIC",
    landscapeRestorationTAS: "landscapeRestorationTAS",
    landscapeRestorationACT: "landscapeRestorationACT",
    landscapeRestorationNSW: "landscapeRestorationNSW",
    landscapeRestorationQLD: "landscapeRestorationQLD",
});

export const ESeedBankDataFields  = Object.freeze({ 
    seedBankNT : "seedBankNT",
    seedBankWA : "seedBankWA",
    seedBankSA : "seedBankSA",
    seedBankVIC : "seedBankVIC",
    seedBankTAS : "seedBankTAS",
    seedBankACT : "seedBankACT",
    seedBankNSW : "seedBankNSW",
    seedBankQLD : "seedBankQLD",
    seedBankDescription: "seedBankDescription"
});

export const ESeedTestingDataFields  = Object.freeze({ 
    seedTestingNT: "seedTestingNT",
    seedTestingWA: "seedTestingWA",
    seedTestingSA: "seedTestingSA",
    seedTestingVIC: "seedTestingVIC",
    seedTestingTAS: "seedTestingTAS",
    seedTestingACT: "seedTestingACT",
    seedTestingNSW: "seedTestingNSW",
    seedTestingQLD: "seedTestingQLD",
});

export const EOtherServiceDataFields  = Object.freeze({ 
    otherServiceNT: "otherServiceNT",
    otherServiceWA: "otherServiceWA",
    otherServiceSA: "otherServiceSA",
    otherServiceVIC: "otherServiceVIC",
    otherServiceTAS: "otherServiceTAS",
    otherServiceACT: "otherServiceACT",
    otherServiceNSW: "otherServiceNSW",
    otherServiceQLD: "otherServiceQLD",
    otherServiceDescription: "otherServiceDescription",
});

export interface IUserProfileSeedInterests {
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    isOther: boolean;
};

export interface IOrgProfileSeedInterests {
    isSeedCollector: boolean;
    isResearchOrganisation: boolean;
    isSeedBank: boolean;
    isSeedProductionArea: boolean;
    isLandscapeRestoration: boolean;
    isNursery: boolean;
    isSeedTesting: boolean;
    isOther: boolean;
};

export interface IResource {
    title: string;
    summary: string;
    link: string;
    topic: string;
    contents: string;
    image: {
        publicURL: string;
    };
    sections: ISection[];
    pdfFile: {
        publicURL: string;
    };
};