import React from 'react';
import ReactPaginate from 'react-paginate';
import LoadingOverlay from 'react-loading-overlay';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { loaderStyle, waitingText } from '../../../constants/common';
import './PaginatedContent.scss';

interface IPaginatedContent {
    pageNumber?: number;
    pageSize?: number;
    recordCount?: number;
    onPageChanged: Function;
    onPageSizeChanged?: Function;
    isLoading?: boolean;
    children: any;
}

const PaginatedContent : React.FC<IPaginatedContent> = (props: IPaginatedContent) => {
    const handlePageClick = ({ selected }: any) => {
        props.onPageChanged(selected + 1);
    };

    const onPageSizeChanged = (pageSize: any) => {
        props.onPageSizeChanged && props.onPageSizeChanged(pageSize as number);
    };

    const pageCount = props?.recordCount ? (props.recordCount / (props?.pageSize || 1)) : 0
    
    return (
        <div className="paginated-content">
            <LoadingOverlay active={props.isLoading} spinner text={waitingText} styles={loaderStyle}>
                <div className="pc-contents">{props.children}</div>
                <div className="pc-paginator">
                <DropdownButton title={props.pageSize} onSelect={onPageSizeChanged}>
                    <Dropdown.Item eventKey="10">10</Dropdown.Item>
                    <Dropdown.Item eventKey="25">25</Dropdown.Item>
                    <Dropdown.Item eventKey="50">50</Dropdown.Item>
                    <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>

                <ReactPaginate  previousLabel={"‹"}
                                nextLabel={"›"}
                                forcePage={(props?.pageNumber ? props.pageNumber : 1) - 1}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"page-item disabled"}
                                activeClassName={"page-item active"}
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                />
            </div>
            </LoadingOverlay>
        </div>
    );
};

export default PaginatedContent;