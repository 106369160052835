import { IFilterItem } from "../components/shared/FilterDropdown/filterDropdown";
import { EUserProfileSeedInterests } from "./enums";

export const types: IFilterItem[] = [
    { key: "", label: "All" },
    { key: "P", label: "Person" },
    { key: "O", label: "Organisation" }
];

export const states: IFilterItem[] = [
    { key: "", label: "All" },
    { key: "ACT", label: "Australian Capital Territory" },
    { key: "NSW", label: "New South Wales" },
    { key: "NT", label: "Northern Territory" },
    { key: "SA", label: "South Australia" },
    { key: "QLD", label: "Queensland" },
    { key: "TAS", label: "Tasmania" },
    { key: "VIC", label: "Victoria" },
    { key: "WA", label: "Western Australia" }
];

export const interests: IFilterItem[] = [
    { key: "", label: "All" },
    { key: EUserProfileSeedInterests.isSeedCollector, label: "Seed Collector" },
    { key: EUserProfileSeedInterests.isResearchOrganisation, label: "Research Organisation" },
    { key: EUserProfileSeedInterests.isSeedBank, label: "Seed Bank" },
    { key: EUserProfileSeedInterests.isSeedProductionArea, label: "Seed Production Area" },
    { key: EUserProfileSeedInterests.isLandscapeRestoration, label: "Landscape Restoration" },
    { key: EUserProfileSeedInterests.isOther, label: "Other" },
    { key: EUserProfileSeedInterests.isNursery, label: "Nursery" },
    { key: EUserProfileSeedInterests.isSeedTesting, label: "Seed Testing" },
    { key: EUserProfileSeedInterests.useOrgSeedInterest, label: "Use Org Seed Interest" },
];

export const sortList: IFilterItem[] = [
    { key: "firstname", label: "First Name"},
    { key: "lastname", label: "Last Name"},
    { key: "suburb", label: "Suburb" },
    { key: "state", label: "State" },
];