import React from 'react';
import { IMoodleBadge } from '../../../models';

import './MoodleBadge.scss';

export const getBadgeJSX = (badge : IMoodleBadge) => {
    return (
        <MoodleBadge badge={badge} />
    )
}

const MoodleBadge = ({badge}) => {

    return (
        <span style={{paddingRight: "20px"}} className="badge-container">
            <img alt={badge.name} className="image"  src={`data:image/png;base64,${badge.b64data}`} />
            <div className="middle">
                <div className="badge-name">{badge.name}</div>
            </div>
        </span>
    );
}

export default MoodleBadge;