import React from 'react';
import { useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

import './filterDropDown.scss';

export interface IFilterItem {
    key: any;
    label: string;
}

const FilterDropdown = ({ title, items, onItemClicked }) => {
    const [selectedItem, setSelectedItem] = useState(0);

    const onClicked = (i: number) => {
        setSelectedItem(i);
        onItemClicked(i);
    };

    const titleDiv: any = (
        <div className="titleDiv">{title}:
            <span>{items[selectedItem]?.label}</span>
        </div>
    );

    return (
        <div className="filter-dropdown">
            <DropdownButton
                as={ButtonGroup}
                key={title}
                id={`dropdown-${title.replace(' ', '-')}`}
                variant=''
                title={titleDiv}
            >
                {
                    !items.length ? <></> :
                    items.map((item: IFilterItem, i: number) =>
                        <Dropdown.Item key={i} as="button" onClick={(e) => onClicked(i)} className={i === selectedItem ? 'active' : ''}>
                            {item.label}
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </div>
    );
}

export default FilterDropdown;