import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import { global, loaderStyle, waitingText } from '../../constants/common';
import { getBadges, getSpaceDelimitedString } from '../../helpers/functions';
import { IUserprofile } from '../../models/cmsModels';
import { cmsCollections, getRecord } from '../../services/cmsService';
import labelMappings from '../../constants/labelMappings';
import ToastContainer, { EMessageModeEnum } from '../shared/Toast/ToastContainer';

import { getBadgeJSX } from '../shared/Badge/MoodleBadge';

interface IProps {
    id: string
}

const UserDetail = (props: IProps) => {
    const [ user, setUser] = useState(null as IUserprofile)
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);

    useEffect(() => {
        const fn = async () => {
            setIsLoading(true);
            const result = await getRecord('', cmsCollections.Userprofiles, props.id);
            setIsLoading(false);
            
            let dataWithBadges : IUserprofile = {} as IUserprofile;
            const user = result?.data;
            let badges = null;
            if (user?.moodleuserid) {
                badges = await getBadges(user?.moodleuserid, showMessage)
            }

            dataWithBadges = {
                ...user,
                badges
            };
            setUser(dataWithBadges);
        };

        if(!user) fn();
    }, []);

    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

    const groups = user?.userData;
    const gKeys = groups ? Object.keys(groups) : [];

    const getLabel = (gKey: string, iKey: string) => {
        return labelMappings[iKey] || getSpaceDelimitedString(iKey, 'Data', gKey, 'ServiceIn');
    };

    const getDetails = (gKey: string): any => {
        const interestNode = groups[gKey];
        let lines = [];

        if (interestNode) {
            const interests = interestNode[0];
            const iKeys = Object.keys(interests);

            let prevVal: null;
            lines = iKeys?.map((iKey: string, i: number) => {
                const iVal = interests[iKey];
                const line = (
                    iVal === true ? (
                        <>
                            {prevVal === true ? ', ' : ''}
                            <span className="flag">{getLabel(gKey, iKey)}</span>
                        </>
                    ) :
                    (iVal && (iVal !== false)) ? (
                        <div className="my-2">
                            <span className="str">{getLabel(gKey, iKey) + ':'}</span>&nbsp;
                            <span className='val'>{iVal}</span>
                        </div>
                        ) : <></>
                );
                prevVal = iVal;
                return line;
            });
        }

        

        return (
            <div className="interests mx-4">
                {lines}
            </div>
        );
    }

    const name = user && user.firstname + ' ' + user.lastname;
    const label = "label col-sm-5 col-md-4";
    const text = "text col-sm-7 col-md-8";

    return (
        <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
            <ToastContainer message={message} mode={messageMode} />
            <div className="user-detail fl-page container">
                <div className="submenu mb-2">
                    <Link className="btn btn-outline-secondary btn-md" role="button" to="/seed-people-directory">
                    <FontAwesomeIcon icon='filter' className="fa" />&nbsp;&nbsp;Directory View
                    </Link>
                </div>
                <Row className="profile mb-4">
                    <Col md={3} lg={2} className="sp-col-img">
                        <img src={user?.userPhoto?.url}></img>
                    </Col>
                    <Col sm={7} md={5} lg={6} className="sp-col">
                        <Row>
                            <div className={label}>Name</div>
                            <div className={text}>{name}</div>
                        </Row>
                        <Row>
                            <div className={label}>Email</div>
                            <div className={text}>{user?.email}</div>
                        </Row>
                        <Row>
                            <div className={label}>Mobile #</div>
                            <div className={text}>{user?.mobilenumber}</div>
                        </Row>
                        <Row>
                            <div className={label}>Organisation</div>
                            <div className={text}>{user?.organisation}</div>
                        </Row>
                        <Row>
                            <div className={label}>Location</div>
                            <div className={text}>{user?.suburb}, {user?.state}</div>
                        </Row>
                    </Col>
                    <Col sm={2} md={4} lg={4} className="sp-col-interests">
                        { user?.moodleuserid && user?.badges?.length ? 
                            (
                            <>
                                <h6>Badges Earned:</h6>
                                <div className="badges">
                                    {
                                    !user?.badges ? <></> : 
                                    user?.badges.map((badge) => {
                                        return getBadgeJSX(badge)
                                    })
                                    }
                                </div>    
                            </>
                            ) : <></>
                        }
                    </Col>
                </Row>
                {
                    !gKeys ? <></> :
                    gKeys.map((gKey: string, g: number) => {
                        return (
                            <div key={g} className="group mb-4">
                                <h5>{labelMappings[gKey] || getSpaceDelimitedString(gKey)}</h5>
                                {getDetails(gKey)}
                            </div>
                        )
                    })
                }
            </div>
        </LoadingOverlay>
    );
};

export default UserDetail;