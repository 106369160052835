import { ENurseryDataFields, ESeedCollectorDataFields, ESeedProductionAreaDataFields } from "../models/cmsModels";

const labelMappings = {
    [ESeedCollectorDataFields.seedCollectorServiceExpertiseDescription]: 'Service Description',
    [ESeedCollectorDataFields.seedCollectorSpecialistCleaning]: 'Specialist Cleaning Equipment',
    [ESeedCollectorDataFields.seedCollectorSpecialistStorage]: 'Specialist Storage  Equipment',
    [ESeedCollectorDataFields.seedCollectorLocation]: 'Collection area in Km',
    [ESeedProductionAreaDataFields.seedProductionAreaSpecialistCleaning]: 'Specialist Cleaning Equipment',
    [ESeedProductionAreaDataFields.seedProductionAreaSpecialistStorage]: 'Specialist Storage  Equipment',
    esearchOrganisationDescription: 'Description of research',
    [ESeedCollectorDataFields.seedCollectorServiceExpertiseDescription]: 'Further description of services/areas of expertise',
    'seedProductionAreaData': 'Seed Production Area - Specialisation / Strata\'s'
};

export default labelMappings;