export enum EUserProfileSeedInterests {
    isSeedCollector= "isSeedCollector",
    isResearchOrganisation= "isResearchOrganisation",
    isSeedBank= "isSeedBank",
    isSeedProductionArea= "isSeedProductionArea",
    isLandscapeRestoration= "isLandscapeRestoration",
    isNursery= "isNursery",
    isSeedTesting= "isSeedTesting",
    isOther= "isOther",
    useOrgSeedInterest= "useOrgSeedInterest"
};

export enum EOrgProfileSeedInterests {
    isSeedCollector= "isSeedCollector",
    isResearchOrganisation= "isResearchOrganisation",
    isSeedBank= "isSeedBank",
    isSeedProductionArea= "isSeedProductionArea",
    isLandscapeRestoration= "isLandscapeRestoration",
    isNursery= "isNursery",
    isSeedTesting= "isSeedTesting",
    isOther= "isOther",
};

export enum EFieldName {
    isSeedCollector= "Seed Collector",
    isResearchOrganisation = "Research Organisation",
    isSeedBank = "Seed Bank / Conservation Seed Bank",
    isSeedProductionArea = "Production Area",
    isLandscapeRestoration = "Landscape Restoration",
    isNursery =  "Nursery",
    isSeedTesting ="Seed Testing",
    isOther = "Other",
};

export enum EScreenParams {
    minWidthForMobile = 500
};