import React, { useImperativeHandle, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import FilterDropdown from '../shared/FilterDropdown/filterDropdown';
import { types, states, interests, sortList } from '../../constants/data';

import './seedPeopleFilterBar.scss';

export interface ISeedPeopleFilters {
    type: string;
    state: string;
    interest: string;
    sort: string;
}

interface IProps {
    onFilterChanged?: any;
}

const SeedPeopleFilterBar = React.forwardRef((props: IProps, ref: any) => {
    const init: ISeedPeopleFilters = {
        type: types[0].key,
        state: states[0].key,
        interest: interests[0].key,
        sort: sortList[0].key
    };

    const [ filters, setFilters ] = useState(init);

    useImperativeHandle(ref, () => (
        {
            getFilters: () => {
                return filters;
            }
        })
    );
    
    const setFilter = (filter: any) => {
        const newFilters = {...filters, ...filter};
        setFilters(newFilters);
        props.onFilterChanged(newFilters);
    };

    return (
        <Container className="filter-bar">
            <Row>
                <Col sm={6} md={1} lg={1}><label className="col-sm-12">Filters</label></Col>
                <Col sm={6} md={4} lg={4}><FilterDropdown title="State" items={states} onItemClicked={(i) => setFilter({ state: states[i].key })} /></Col>
                <Col sm={6} md={4} lg={4}><FilterDropdown title="Interests" items={interests} onItemClicked={(i) => setFilter({ interest: interests[i].key })} /></Col>
                <Col sm={6} md={3} lg={3}><FilterDropdown title="Sort" items={sortList} onItemClicked={(i) => setFilter({ sort: sortList[i].key })} /></Col>
            </Row>
        </Container>
    );
});

export default SeedPeopleFilterBar;